import memoize from 'lodash/memoize';
import { selectPagination } from 'redux/helpers/pagination';
import { createSelector } from 'reselect';

const getCourseEntities = ({ entities: { learningCourses } }) => learningCourses;
const getCourseVariantEntities = ({ entities: { learningCourseVariants } }) => learningCourseVariants;

const getCourseSelector = createSelector(
  [getCourseEntities],
  courses => memoize(
    id => courses?.[id],
  ),
);

const getCoursesByFilterForMember = createSelector(
  [state => state.course.coursesForMember],
  courses => memoize(
    filter => selectPagination(courses, filter, 'courseIds'),
  ),
);

const getCourseForMember = createSelector(
  [getCourseEntities],
  courses => memoize(
    courseId => courses?.[courseId],
  ),
);

const getCourseIdsPaginationForAdmin = ({ course: { coursesForAdmin } }) => selectPagination(coursesForAdmin, null, 'courseIds');

const getCoursesPaginationForAdmin = createSelector(
  [getCourseIdsPaginationForAdmin, getCourseSelector],
  (paginationData, getCourse) => ({
    ...paginationData,
    courses: paginationData.courseIds?.map(getCourse).filter(v => !!v),
  }),
);

const getCourseForAdmin = createSelector(
  [getCourseEntities],
  courses => memoize(
    courseId => courses?.[courseId],
  ),
);

const getCourseVariantSelector = createSelector(
  [getCourseVariantEntities],
  courseVariants => memoize(
    variantId => courseVariants?.[variantId],
  ),
);

const getCourseVariantsForAdmin = createSelector(
  [getCourseForAdmin, getCourseVariantSelector],
  (courseSelector, courseVariantSelector) => memoize((courseId) => {
    return courseSelector(courseId)?.variants?.map(courseVariantSelector).filter(v => !!v) || [];
  }),
);

const getCourseVariantForAdmin = createSelector(
  [getCourseVariantEntities],
  courseVariants => memoize(
    courseVariantId => courseVariants?.[courseVariantId],
  ),
);

export default {
  getCoursesByFilterForMember,
  getCourseForMember,
  getCourseIdsPaginationForAdmin,
  getCoursesPaginationForAdmin,
  getCourseForAdmin,
  getCourseVariantsForAdmin,
  getCourseVariantForAdmin,
};
