import combineReducers from 'combine-reducers-global-state';
import { createPagination, addToPagination, resetPagination, parsePagination, setPaginationLoading } from 'redux/helpers/pagination';
import { groupTypes } from 'redux/group';
import isEqual from 'lodash/isEqual';
import mergeWith from 'lodash/mergeWith';
import isArray from 'lodash/isArray';
import { contactTypes } from 'redux/contact';
import update from 'lodash/update';
import types from './types';

const profile = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getWorkspaceProfileSuccess:
    case types.updateWorkspaceProfileSuccess:
      return payload;
    default:
      return state;
  }
};

const settings = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getWorkspaceSettingsSuccess:
    case types.updateWorkspaceSettingsSuccess:
      return payload;
    default:
      return state;
  }
};

const pulseSettings = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getWorkspacePulseSettingsSuccess:
    case types.updateWorkspacePulseSettingsSuccess:
      return payload;
    default:
      return state;
  }
};

const user = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getUserSuccess:
      return payload;
    default:
      return state;
  }
};

const labels = (state = [], { type, payload }) => {
  switch (type) {
    case types.getLabelsSuccess:
      return payload.type === 'DEFAULT' ? payload.data : state;
    default:
      return state;
  }
};

const labelsLoading = (state = false, { type, payload }) => {
  switch (type) {
    case types.getLabels:
      return true;
    case types.getLabelsSuccess:
    case types.getLabelsFailure:
      return payload.type === 'DEFAULT' ? false : state;
    default:
      return state;
  }
};


const analyticalLabels = (state = [], { type, payload }) => {
  switch (type) {
    case types.getLabelsSuccess:
      return payload.type === 'ANALYTICAL' ? payload.data : state;
    default:
      return state;
  }
};

const analyticalLabelsLoading = (state = false, { type, payload }) => {
  switch (type) {
    case types.getLabels:
      return true;
    case types.getLabelsSuccess:
    case types.getLabelsFailure:
      return payload.type === 'ANALYTICAL' ? false : state;
    default:
      return state;
  }
};

const channelIds = (state = [], { type, payload }) => {
  switch (type) {
    case types.getWorkspaceChannelsSuccess:
      return payload.data;
    default:
      return state;
  }
};

const groups = (state = [], { type, payload }) => {
  switch (type) {
    case types.getGroupsSuccess:
      return payload.data;
    case groupTypes.storeSuccess:
      return [...state, payload.data];
    case groupTypes.deleteSuccess:
      return state.filter(sub => +sub.id !== +payload.groupId);
    default:
      return state;
  }
};

const invitation = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getInvitationSuccess:
    case types.regenerateInvitationSuccess:
      return payload;
    default:
      return state;
  }
};

const memberProfiles = (state = {}, { type, payload }) => {
  switch (type) {
    case types.getMemberSuccess:
      return { ...state, [payload.id]: payload };
    default:
      return state;
  }
};


const allMembers = (state = createPagination(null, 'memberIds'), { type, payload }) => {
  switch (type) {
    case types.getMembers:
      return setPaginationLoading(state, null, true);
    case types.getMembersFailure:
      return setPaginationLoading(state, null, false);
    case types.getMembersSuccess:
      return addToPagination(state, null, payload, 'memberIds');
    default:
      return state;
  }
};

const initialMemberRoles = { all: [], loading: false };
const memberRoles = (state = initialMemberRoles, { type, payload }) => {
  switch (type) {
    case types.getMemberRoles:
      return { ...state, loading: true };
    case types.getMemberRolesSuccess:
      return { ...state, loading: false, all: payload.data };
    case types.getMemberRolesFailure:
      return { ...state, loading: false };
    default:
      return state;
  }
};

const members = combineReducers({
  all: allMembers,
  profiles: memberProfiles,
  roles: memberRoles,
});

const contactDirectoryList = (state = createPagination(null, 'items'), { type, payload }) => {
  switch (type) {
    case types.getContactDirectory:
      return payload.page <= 1 ? resetPagination(state, null, 'items') : state;
    case types.getContactDirectorySuccess:
      return addToPagination(state, null, payload, 'items');
    default:
      return state;
  }
};

function createPeopleDirectoryTreeStructure({ entities, data }) {

  const separator = '/';
  const itemsKey = '$items';

  const structure = {};

  (data || []).forEach(({ type, data: id }) => {
    const item = type === 'contact'
      ? entities.contacts[id]
      : entities.members[id];

    const path = (item?.structuralUnit || '')
      .split(separator)
      .map(value => value.trim())
      .filter(value => !!value)
      .concat([itemsKey]);

    update(structure, path, value => [
      ...(value || []),
      { type, id },
    ]);
  });

  return structure;
}

const contactDirectoryTreeInitial = {
  ...createPagination(null, 'items'),
  items: {}, // Use object instead of default array
};

const mergeWithFn = (objValue, srcValue) => {
  if (isArray(objValue)) {
    return [
      ...objValue,
      ...srcValue,
    ];
  }
};

// Trying to replicate actual pagination queries,
// but data is a special kind of object
const contactDirectoryTree = (state = contactDirectoryTreeInitial, { type, payload }) => {
  if (type === types.getContactDirectory && payload.page <= 1) {
    return contactDirectoryTreeInitial;
  }

  if (type === contactTypes.storeContactSuccess) {
    const treeStructure = createPeopleDirectoryTreeStructure({
      entities: payload.entities,
      data: [{
        type: 'contact',
        data: payload.data,
      }],
    });

    return {
      items: mergeWith({}, state.items, treeStructure, mergeWithFn),
      pagination: state.pagination,
    };
  }

  if (type === types.getContactDirectorySuccess) {
    const treeStructure = createPeopleDirectoryTreeStructure(payload);

    return {
      items: payload.meta?.currentPage === 1
        ? treeStructure // Replace
        : mergeWith({}, state.items, treeStructure, mergeWithFn),
      pagination: parsePagination(payload),
    };
  }

  return state;
};


const contactDirectory = combineReducers({
  list: contactDirectoryList,
  tree: contactDirectoryTree,
});

const challenges = (state = {
  all: createPagination(null, 'challengeIds'),
  active: createPagination(null, 'challengeIds'),
  finished: createPagination(null, 'challengeIds'),
  templates: [],
}, { type, payload }) => {
  const child = ({
    1: 'active',
    0: 'finished',
    all: 'all',
  })[payload && payload.active !== undefined ? payload.active : 'all'];

  switch (type) {
    case types.getChallengesSuccess:
      return addToPagination(state, child, payload, 'challengeIds');
    case types.endChallengeSuccess:
    case types.updateChallengeSuccess:
    case types.storeChallengeSuccess:
      return {
        ...state,
        all: resetPagination(state.all, null, 'challengeIds'),
        active: resetPagination(state.active, null, 'challengeIds'),
        finished: resetPagination(state.finished, null, 'challengeIds'),
      };
    case types.getChallengeTemplatesSuccess:
      return {
        ...state,
        templates: payload,
      };
    default:
      return state;
  }
};

const analyticsTypeReducer = (startType, successType, failureType) => (state = {
  day: { all: [], loading: false },
  month: { all: [], loading: false },
  week: { all: [], loading: false },
}, { type, payload }) => {
  switch (type) {
    case startType:
      return { ...state, loading: true };
    case successType:
      return {
        ...state,
        loading: false,
        [payload.period]: {
          ...state[payload.period],
          [payload.labelId || 'all']: payload.data,
        },
      };
    case failureType:
      return { ...state, loading: false };
    default:
      return state;
  }
};

const search = (state = {
  ...createPagination(null, 'results'),
  loading: false,
  term: undefined,
  filter: undefined,
  autocomplete: [],
}, { type, payload }) => {
  switch (type) {
    case types.workspaceSearch:
      return payload.term !== state.term || !isEqual(payload.filter, state.filter)
        ? { ...state, ...resetPagination(state, null, 'results'), term: payload.term, filter: payload.filter, loading: true }
        : { ...state, term: payload.term, filter: payload.filter, loading: true };
    case types.workspaceSearchSuccess:
      return { ...state, ...addToPagination(state, null, payload, 'results'), loading: false };
    case types.workspaceSearchFailure:
      return { ...state, loading: false };

    case types.workspaceSearchAutocomplete:
    case types.workspaceSearchAutocompleteFailure:
      return { ...state, autocomplete: [] };

    case types.workspaceSearchAutocompleteSuccess: {
      const suggestions = payload.data.map(((result) => {
        switch (result.type) {
          case 'member':
            return payload.entities.members[result.data].name;
          case 'group':
            return payload.entities.groups[result.data].name;
          case 'page':
            return payload.entities.pages[result.data].name;
          case 'course':
            return payload.entities.learningCourses[result.data].name;
          default:
            return null;
        }
      })).filter(o => !!o);

      return { ...state, autocomplete: suggestions };
    }

    default:
      return state;
  }
};

const analytics = combineReducers({
  overview: analyticsTypeReducer(types.getAnalyticsOverview, types.getAnalyticsOverviewSuccess, types.getAnalyticsOverviewFinish),

  pulse: analyticsTypeReducer(types.getAnalyticsPulse, types.getAnalyticsPulseSuccess, types.getAnalyticsPulseFinish),
  funnel: analyticsTypeReducer(types.getAnalyticsFunnel, types.getAnalyticsFunnelSuccess, types.getAnalyticsFunnelFinish),

  activeness: analyticsTypeReducer(types.getAnalyticsActiveness, types.getAnalyticsActivenessSuccess, types.getAnalyticsActivenessFinish),
  adoption: analyticsTypeReducer(types.getAnalyticsAdoption, types.getAnalyticsAdoptionSuccess, types.getAnalyticsAdoptionFinish),
  engagement: analyticsTypeReducer(types.getAnalyticsEngagement, types.getAnalyticsEngagementSuccess, types.getAnalyticsEngagementFinish),

  posts: analyticsTypeReducer(types.getAnalyticsPosts, types.getAnalyticsPostsSuccess, types.getAnalyticsPostsFinish),
  comments: analyticsTypeReducer(types.getAnalyticsComments, types.getAnalyticsCommentsSuccess, types.getAnalyticsCommentsFinish),
  likes: analyticsTypeReducer(types.getAnalyticsLikes, types.getAnalyticsLikesSuccess, types.getAnalyticsLikesFinish),

  members: analyticsTypeReducer(types.getAnalyticsMembers, types.getAnalyticsMembersSuccess, types.getAnalyticsMembersFinish),
  users: analyticsTypeReducer(types.getAnalyticsUsers, types.getAnalyticsUsersSuccess, types.getAnalyticsUsersFinish),
});

const workspaceReducer = combineReducers({
  profile,
  settings,
  pulseSettings,
  user,
  labels,
  labelsLoading,
  analyticalLabels,
  analyticalLabelsLoading,
  channelIds,
  groups,
  invitation,
  members,
  contactDirectory,
  challenges,
  analytics,
  search,
});

export default { workspace: workspaceReducer };
