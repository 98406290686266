import React from 'react';
import Grid from 'components/Grid';
import styled from '@emotion/styled/macro';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import IconButton from '@mui/material/IconButton';
import NavLink from 'components/NavLink';
import { isMobile } from 'helpers';
import PropTypes from 'propTypes';

const HeaderWrapper = styled('div')`
  position: fixed;
  top: ${isMobile ? 0 : '52px'};
  left: 0;
  width: 100%;
  min-height: ${isMobile ? '56px' : '64px'};
  padding: 0 1rem;
  background: #fff;
  color: #666;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  z-index: ${({ theme }) => theme.zIndex.fullScreenHeader};
`;

const HeaderGridContainer = styled(Grid)`
  height: ${isMobile ? '56px' : '64px'};
`;

const HeaderGridItem = styled(Grid)`
  min-width: 20%;
  height: 100%;

  display: flex;
  align-items: center;
`;

const LeftGridItem = styled(HeaderGridItem)`
  justify-content: flex-start;
`;

const CenterGridItem = styled(HeaderGridItem)`
  justify-content: center;
`;

const RightGridItem = styled(HeaderGridItem)`
  justify-content: flex-end;
`;

const HeaderTitle = styled('div')`
  font-weight: 600;
  font-size: 0.9rem;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const FullScreenHeader = ({ backUrl, title, rightComponent }) => {
  return (
    <HeaderWrapper>
      <HeaderGridContainer container direction="row" wrap="nowrap" justifyContent="space-between" alignItems="center">

        <LeftGridItem item>
          <IconButton component={NavLink} size="small" to={backUrl}><ArrowBackIosRoundedIcon /></IconButton>
        </LeftGridItem>

        <CenterGridItem item>
          <HeaderTitle>{title}</HeaderTitle>
        </CenterGridItem>

        <RightGridItem item>
          {rightComponent}
        </RightGridItem>

      </HeaderGridContainer>
    </HeaderWrapper>
  );
};

FullScreenHeader.propTypes = {
  backUrl: PropTypes.string,
  title: PropTypes.string,
  rightComponent: PropTypes.component,
};

export default FullScreenHeader;
