import union from 'lodash/union';
import mapValues from 'lodash/mapValues';
import memoize from 'lodash/memoize';
import isEqual from 'lodash/isEqual';

export const initialPagination = {
  currentPage: 0,
  lastPage: undefined,
  total: undefined,
  hasMore: true,
  nextPage: 1,
};



export const parsePagination = ({ data, meta: { currentPage, lastPage, total, perPage } = initialPagination }) => ({
  currentPage,
  lastPage,
  total,
  hasMore: lastPage ? currentPage < lastPage : data?.length >= perPage,
  nextPage: currentPage + 1,
});

export const createPagination = memoize((id, dataKey = 'data') => {
  const empty = {
    [dataKey]: [],
    latest: [],
    pagination: initialPagination,
    isLoading: false,
    dataKey,
  };

  return id ? { [id]: empty } : empty;
}, (...args) => `createPagination-${args.filter(v => !!v).join('-')}`);

export const setPaginationLoading = (state, id, isLoading = true) => {
  if (id === '*') {
    return mapValues(state, (value) => setPaginationLoading(value, null, isLoading));
  }

  return state.isLoading === isLoading
    ? state
    : { ...state, isLoading };
};


export const addToPagination = (state, id, payload, dataKey = 'data') => {
  const currentData = id && state[id] ? state[id][dataKey] : state[dataKey];

  // If it's first page, then replace the results (start over)
  const nextData = payload?.meta?.currentPage <= 1
    ? payload.data
    : union(currentData || [], payload.data);

  const core = {
    [dataKey]: nextData,
    latest: payload.data,
    pagination: parsePagination(payload),
    isLoading: false,
  };

  return {
    ...state,
    ...(id ? { [id]: core } : core),
  };
};


export const resetPagination = (state, id, dataKey = 'data') => {
  if (id === '*') {
    return mapValues(state, (value) => resetPagination(value, null, dataKey));
  }

  const next = {
    ...state,
    ...createPagination(id, dataKey),
  };

  return isEqual(next, state) ? state : next;
};

export const selectPagination = (state, id, dataKey = 'data') => {
  const rootState = id && state ? state[id] : state;

  if (rootState && rootState[dataKey]) {
    return rootState;
  }

  return createPagination(null, dataKey);
};
